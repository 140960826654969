<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select class="filter-item" v-model="query.entId" :label.sync="query.entName" filterable value-field="enterpriseId" url="api/distributor" :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }" placeholder="请选择经销商" @change="toQuery" style="width: 230px;"  clearable>
                <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
              </quick-select>    
      <el-input v-model="query.name" :maxlength="20" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.code" :maxlength="20" clearable placeholder="输入商品编码搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button type="info" @click="upload" class="filter-item" icon="el-icon-upload2" >导入期初</el-button>
      <el-button type="info" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
      <el-button type="warning" @click="clear" class="filter-item" icon="el-icon-error" >清除期初</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">      
      <el-table-column prop="distributor.erpId" label="经销商编码" min-width="130"/>
      <el-table-column prop="distributor.alias" label="经销商名称" min-width="130"/>
      <el-table-column prop="goodsSku.erpCode" label="商品ERP编码" min-width="130"/>
      <el-table-column prop="goodsSku.name" label="商品名称" min-width="240px"/>
      <el-table-column prop="goodsSku.specs" label="规格" min-width="160" :formatter="$goodsSpecConvert"/>      
      <el-table-column prop="count" label="期初数量" width="120px" align="right" />
      <el-table-column prop="price" label="单价" width="120px" align="right" >
        <template slot-scope="scope">{{$price(scope.row.price)}}</template>
      </el-table-column>        
      <el-table-column prop="amount" label="金额" width="120px" align="right">
        <template slot-scope="scope">{{ $price(scope.row.price*scope.row.count) }} </template>
      </el-table-column>
      <el-table-column prop="createAt" label="导入时间" width="120px">
        <template slot-scope="scope">
            {{ new Date(scope.row.createAt).format("yyyy-MM-dd hh:mm") }}
          </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <importForm ref = "importForm"/>
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import importForm from "./detail"
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";

export default {
  components: { importForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      query:{
        warehouseIds:null,
        brandId:null,
        seriesId:null,
        categoryId:null,
        minCount:null,
        maxCount:null,
        name:null,
        code:null,
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/stockReportBop";
      this.params = this.query;
      return true;
    },
    upload(){
      if(!this.query.entId) {
        this.$message.warning("请选择经销商");
        return;
      }
      this.$refs.importForm && this.$refs.importForm.resetForm(this.query.entId,this.query.entName);
    },
    downloadTemplate() {
            this.downloadLoading = true;
            download("api/stockReportBop/template")
                .then(result => {
                    downloadFile(result, "期初库存模板", "xlsx");
                    this.downloadLoading = false;
                })
                .catch(err => {
                    this.$message.error(err.message || "操作失败，请稍候再试。");
                    this.downloadLoading = false;
                });
        },
    clear(){
      if(!this.query.entId) {
        this.$message.warning("请选择经销商");
        return;
      }
      this.$confirm(
        "您确定要清除该经销商的期初数据吗？ 此操作不可恢复",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        request({
            url: 'api/stockReportBop/clear/'+this.query.entId,
            method: "delete"
        }).then(_=>{
          this.init();
            this.$message({
              type: "success",
              message: "清除成功!"
            });
        }).catch(err=>{
          this.$message.error("清除失败,请稍后重试");
        });
      });
    },
    toDownload() {
      if(!!!this.params.entId) {
        this.$message.info("请选择经销商");
        return;
      }
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/stockReportBop/download", this.params).then((result) => {
          downloadFile(result, "期初库存", "xlsx");
          this.downloadLoading = false;
      })
      .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
      });
    }
  }
};
</script>